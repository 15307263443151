import React, { useState, useRef, useEffect } from 'react'
import { useStoreToken } from './APIKeyModal'
import { getApiKey, getToken } from 'models/Authentication'
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import { errorHandler } from 'utils/helper'

const Login = ({ onSuccess, onError, setIsValidating, isValidating }) => {
  const textInput = useRef(null)

  const { setData: setTokenData } = useStoreToken()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState({
    username: '',
    password: ''
  })

  const handleSubmit = async () => {
    try {
      setIsValidating(true)
      const getTokenResponse = await getToken(data)
      const getApiKeyResponse = await getApiKey(getTokenResponse.access_token)
      setTokenData(getTokenResponse.access_token, data.username)
      onSuccess(getApiKeyResponse.data['api-key'])
    } catch (error) {
      onError(errorHandler(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  useEffect(() => {
    setTimeout(function () {
      if (textInput.current) {
        textInput.current.focus()
      }
    }, 100)
  }, [])

  return (
    <>
      <Form role='form'>
        <FormGroup>
          <InputGroup className='input-group-alternative'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i id='lock-icon' className={`ni ni-email-83`} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={'Email'}
              type={'email'}
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                const { value } = e.target
                setData((c) => ({ ...c, username: value }))
              }}
              innerRef={textInput}
              name='email'
              value={data.username}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup className='input-group-alternative'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i id='lock-icon' className={`ni ni-key-25`} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={'Password'}
              type={'password'}
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                const { value } = e.target
                setData((c) => ({ ...c, password: value }))
              }}
              innerRef={textInput}
              name='password'
              value={data.password}
            />
          </InputGroup>
        </FormGroup>

        <div className='text-center'>
          <Button
            className='my-2 btn-icon btn-2'
            color='default'
            type='button'
            onClick={handleSubmit}
            disabled={isSubmitting || isValidating}
          >
            Login
            {(isSubmitting || isValidating) && (
              <span className='ml-2'>
                <i
                  className='fa fa-circle-notch fa-spin text-white'
                  style={{ fontSize: '16px' }}
                ></i>
              </span>
            )}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default Login
