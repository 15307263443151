import credentials from 'models/Credentials'
import getIndex from 'requests/ASRIndex'
import Register from './Register'
import create from 'zustand'
import Login from './Login'
import React, { useState } from 'react'
import {
  Modal,
  Card,
  CardBody,
  CardHeader,
  Alert,
  CardFooter,
  Button
} from 'reactstrap'
import { observer } from 'mobx-react'

export const useStoreToken = create((set) => ({
  email: '',
  token: '',
  setEmail: (email) =>
    set(() => ({
      email
    })),
  setToken: (token) =>
    set(() => ({
      token
    })),
  setData: (token, email) =>
    set(() => ({
      token,
      email
    }))
}))

function APIKeyModal() {
  const [login, setLogin] = useState(true)
  const [error, setError] = useState('')
  const [isValidating, setIsValidating] = useState(false)

  const validateCredentials = async () => {
    credentials.setValidating()
    let result = await getIndex()
    if (result.status === 'success') {
      credentials.setValid()
    } else {
      let { status, error } = result
      if (status === 'error_response') {
        if (error.status === 403) {
          setError(
            'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
          )
          credentials.setInvalid(
            'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
          )
        } else {
          if (('' + error.status)[0] === '4') {
            setError('Invalid credential')
            credentials.setInvalid('Invalid credential')
            console.log('asdasdasdsda')
          } else {
            setError('Credential validation failed')
            credentials.setInvalid('Credential validation failed')
          }
        }
      } else if (status === 'error_request') {
        credentials.setInvalid('Credential validation failed')
        setError('Credential validation failed')
      } else {
        credentials.setInvalid('Credential validation failed')
        setError('Credential validation failed')
      }
    }
  }

  const onSuccess = async (apiKey) => {
    credentials.setAPIKey(apiKey)
    await validateCredentials()
    setIsValidating(false)
  }

  const onError = async (message) => {
    credentials.setInvalid(message)
    setError(message)
    setIsValidating(false)
  }

  return (
    <Modal
      className='modal-dialog-centered'
      size='md'
      isOpen={!credentials.isValid}
    >
      <div className='modal-body p-0'>
        <Card className='bg-secondary shadow border-0'>
          <CardHeader className='bg-transparent'>
            <div className='text-center mt-1 mb-1'>
              {login ? 'Sign In' : 'Fill this form to register.'}
            </div>
          </CardHeader>

          <CardBody className='px-lg-5 pt-lg-4 '>
            {login ? (
              <Login
                onSuccess={onSuccess}
                onError={onError}
                setIsValidating={setIsValidating}
                isValidating={isValidating}
              />
            ) : (
              <Register
                onSuccess={onSuccess}
                onError={onError}
                setIsValidating={setIsValidating}
                isValidating={isValidating}
              />
            )}
          </CardBody>

          {error ? <Alert color='warning'>{error}</Alert> : ''}

          <CardFooter
            className='bg-transparent'
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className='my-2 btn-icon btn-2'
                color='default'
                type='button'
                onClick={() => setLogin((c) => !c)}
                disabled={isValidating}
              >
                {login ? 'Register' : 'Login'}
              </Button>
            </span>
          </CardFooter>
        </Card>
      </div>
    </Modal>
  )
}

export default observer(APIKeyModal)
