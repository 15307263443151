/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react';
// core components
import DashboardNavbar from 'components/Navbars/Navbar';
import Sidebar from 'components/Sidebar/Sidebar';
import Footer from 'components/Footers/Footer';

import LogoProsa from 'assets/bareskrim.png';
import routes from 'routes.js';
import APIKeyModal from 'components/Credentials/APIKeyModal';
import credentials from 'models/Credentials';

const appPages = process.env.REACT_APP_PAGES;
const defaultAppPage = 'sid';
const defaultRoute = routes.find((element) => element.id === defaultAppPage);

class DashboardLayout extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/' && appPages.includes(prop.id)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Prosa.ai';
  };
  render() {
    return (
      <>
        <APIKeyModal />
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/',
            imgSrc: LogoProsa,
            imgAlt: '...',
          }}
        />
        <div className='main-content' ref='mainContent'>
          <DashboardNavbar
            {...this.props}
            headerTitle={`Prosa ${this.getBrandText(this.props.location.pathname)}`}
          />
          {credentials.isValid ? (
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from='*' to={defaultRoute.path} />
            </Switch>
          ) : (
            <div style={{ minHeight: '90vh' }} />
          )}
          <Container fluid>
            <Footer />
          </Container>
        </div>
      </>
    );
  }
}

export default observer(DashboardLayout);
