import Axios, { AxiosRequestConfig } from 'axios'
import urlConfig from 'config'

type AuthenticateData = {
  code: number
}

const { origin, basename } = urlConfig.api.auth

export const authenticate = async (token: string) => {
  try {
    const data = {
      token
    }
    const request = await Axios.post(`${origin}/${basename}/login`, data)
    const result: AuthenticateData = request.data
    return result
  } catch (error) {
    return false
  }
}

type RegisterBody = {
  email: string
  password: string
  name: string
  id_number: string
  organization: string
  phone_number: string
}

type AuthResponse = {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: any
  scope: any
}

export const registerUser = async (body: RegisterBody) => {
  const { data } = await Axios.post<AuthResponse>(
    `${origin}/${basename}/register`,
    body
  )
  return data
}

type GetTokenBody = {
  username: string
  password: string
}

export const getToken = async (body: GetTokenBody) => {
  const params = new URLSearchParams()
  Object.keys(body).forEach((key) => {
    params.append(key, body[key as 'username' | 'password'])
  })

  const { data } = await Axios.post<AuthResponse>(
    `${origin}/${basename}/token`,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      }
    }
  )
  return data
}

interface GetApiKeyResponse {
  code: number
  data: Data
}

interface Data {
  'api-key': string
}

export const getApiKey = async (token: string) => {
  const { data } = await Axios.post<GetApiKeyResponse>(
    `${origin}/${basename}/api-key`,
    undefined,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  )
  return data
}

export const verifyAdmin = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` }
    }
    const { data: result } = await Axios.get(
      `${origin}/${basename}/elevate`,
      config
    )
    return result
  } catch (error) {
    return false
  }
}
