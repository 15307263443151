import React, { useState, useRef, useEffect } from 'react'
import { useStoreToken } from './APIKeyModal'
import { getApiKey, registerUser } from 'models/Authentication'
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import { errorHandler } from 'utils/helper'

const INPUT_DATA = [
  {
    placeholder: 'Email',
    icon: 'ni-email-83',
    key: 'email'
  },
  {
    placeholder: 'Password',
    icon: 'ni-key-25',
    key: 'password'
  },
  {
    placeholder: 'Name',
    icon: 'ni-circle-08',
    key: 'name'
  },
  {
    placeholder: 'NIK',
    icon: 'ni-badge',
    key: 'id_number'
  },
  {
    placeholder: 'Organization',
    icon: 'ni-single-02',
    key: 'organization'
  },
  {
    placeholder: 'Phone Number',
    icon: 'ni-mobile-button',
    key: 'phone_number'
  }
]

const Register = ({ onSuccess, onError, setIsValidating, isValidating }) => {
  const textInput = useRef(null)

  const { setData: setTokenData } = useStoreToken()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState({
    email: '',
    password: '',
    name: '',
    id_number: '',
    organization: '',
    phone_number: ''
  })

  useEffect(() => {
    setTimeout(function () {
      if (textInput.current) {
        textInput.current.focus()
      }
    }, 100)
  }, [])

  const handleSubmit = async () => {
    try {
      setIsValidating(true)
      const registerResponse = await registerUser(data)
      const getApiKeyResponse = await getApiKey(registerResponse.access_token)
      setTokenData(registerResponse.access_token, data.email)
      onSuccess(getApiKeyResponse.data['api-key'])
    } catch (error) {
      onError(errorHandler(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Form role='form'>
      {INPUT_DATA.map((item) => (
        <FormGroup>
          <InputGroup className='input-group-alternative'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i id='lock-icon' className={`ni ${item.icon}`} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={item.placeholder}
              type={
                item.key === 'password'
                  ? 'password'
                  : item.key === 'email'
                  ? 'email'
                  : 'text'
              }
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                const { value } = e.target
                setData((c) => ({ ...c, [item.key]: value }))
              }}
              innerRef={textInput}
              name={item.key}
              value={data[item.key]}
            />
          </InputGroup>
        </FormGroup>
      ))}
      <div className='text-center'>
        <Button
          className='my-2 btn-icon btn-2'
          color='default'
          type='button'
          onClick={handleSubmit}
          disabled={isSubmitting || isValidating}
        >
          Register
          {(isSubmitting || isValidating) && (
            <span className='ml-2'>
              <i
                className='fa fa-circle-notch fa-spin text-white'
                style={{ fontSize: '16px' }}
              ></i>
            </span>
          )}
        </Button>
      </div>
    </Form>
  )
}

export default Register
