export const errorHandler = (error) => {
  const data = error?.response?.data
  const status = error?.response?.status

  const fallback = `Something went wrong (${status ?? 'Unknown error'}).`

  if (data) {
    if (status === 422) {
      const { detail } = data
      if (detail && Array.isArray(detail)) {
        return `Invalid ${detail
          .map((item) =>
            item.loc[1] === 'id_number'
              ? 'NIK'
              : item.loc[1] === 'password'
              ? 'password (min 5 characters)'
              : item.loc[1]
          )
          .join(', ')}.`
      } else {
        return fallback
      }
    } else if (data?.message) {
      return data?.message
    } else {
      return fallback
    }
  } else {
    return fallback
  }
}
