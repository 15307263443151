/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

import { GoogleLogout } from 'react-google-login';
import { useStoreToken } from '../Credentials/APIKeyModal';
import { verifyAdmin } from 'models/Authentication';
import { useHistory } from 'react-router-dom';
import { clearCredential } from 'utils/Session';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Collapse,
  Row,
  Col,
} from 'reactstrap';

const AdminContainer = styled.div`
  @media only screen and (min-width: 992px) {
    opacity: 0;
  }
`;

const appPages = process.env.REACT_APP_PAGES;

const RouteAdmin = () => {
  const [disabled, setDisabled] = useState(true);
  const token = useStoreToken((state) => state.token);
  const history = useHistory();

  useEffect(() => {
    if (token === '') return;
    const verifyUser = async () => {
      const result = await verifyAdmin(token);
      if (result !== false) {
        setDisabled(false);
      }
    };
    verifyUser();
  }, [token]);

  if (disabled) {
    return '';
  }

  const handleClickAdmin = () => {
    history.push('/admin');
  };

  return (
    <>
      <button onClick={handleClickAdmin} className='btn btn-success'>
        Admin Page
      </button>
      <hr className='my-3' />
    </>
  );
};

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName='active'
            disabled={!appPages.includes(prop.id)}
            style={{
              color: 'white'
            }}
          >
            <i className={prop.icon} style={{ color: 'white' }} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { logo, routes } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='lg'
        id='sidenav-main'
        style={{ 
          zIndex: '999', 
          background: 'linear-gradient(360deg, #f5f5f1 0%, #e50914 100%)'
        }}
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className='navbar-toggler'
            type='button'
            onClick={this.toggleCollapse}
          >
            <span className='navbar-toggler-icon' />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className='pt-0' {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
                height='100%'
                style={{
                  maxHeight: '6rem'
                }}
              />
            </NavbarBrand>
          ) : null}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className='navbar-collapse-header d-md-none'>
              <Row>
                {logo ? (
                  <Col className='collapse-brand' xs='6'>
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className='collapse-close' xs='6'>
                  <button
                    className='navbar-toggler'
                    type='button'
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className='my-3' />
            <AdminContainer>
              <RouteAdmin />
              <GoogleLogout
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText='Logout'
                render={(renderProps) => {
                  return (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      variant='outlined'
                      color='secondary'
                      fullWidth
                      style={{ textTransform: 'none' }}
                    >
                      Logout
                      <i className='fa fa-sign-out-alt ml-2'></i>
                    </Button>
                  );
                }}
                onLogoutSuccess={() => {
                  clearCredential();
                  window.location.reload();
                }}
              ></GoogleLogout>
            </AdminContainer>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
